import {
	type GasPayload,
	type GasScreenEventPayload,
	OPERATIONAL_EVENT_TYPE,
	SCREEN_EVENT_TYPE,
	TRACK_EVENT_TYPE,
	UI_EVENT_TYPE,
} from '@atlaskit/analytics-gas-types';
import { FabricChannel } from '@atlaskit/analytics-listeners/types';
import { type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	type AnalyticsContext,
	type Notification,
	type NotificationGroup,
	type ProductFilters,
	type RequestCategory,
	type RequestReadState,
} from '@atlassian/notification-types/types';

import { CrossType } from './types';

export const NOTIFICATION_CHANNEL = FabricChannel.notifications;

export type NotificationGasEventPayload = Partial<GasPayload> & {
	attributes: NotificationCommonAttributes & NotificationFilterAttributes;
};

export type NotificationGasOperationalEventPayload = Partial<GasPayload> & {
	attributes: NotificationCommonAttributes & Partial<NotificationFilterAttributes>;
};

export type NotificationCommonAttributes = {
	userHourOfDay?: number;
	notificationAgeInSeconds?: number;
	listIndex?: number;
	notificationCategory?: string;
	notificationReadState?: string;
};

export type NotificationFilterAttributes = {
	categoryFilter: RequestCategory;
	readStateFilter: RequestReadState;
	productFilter: ProductFilters;
};

export const triggerScreenEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	payload: Omit<GasScreenEventPayload, 'eventType'>,
	analyticsContext: AnalyticsContext,
) => {
	const analyticsEvent = createAnalyticsEvent({
		...payload,
		eventType: SCREEN_EVENT_TYPE,
		attributes: {
			...payload.attributes,
		},
		name: payload.name,
		...getTenantFields(analyticsContext),
	});
	analyticsEvent.clone();
	analyticsEvent.fire(NOTIFICATION_CHANNEL);
};

export const triggerUIEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	payload: NotificationGasEventPayload,
	analyticsContext: AnalyticsContext,
) => {
	const analyticsEvent = createAnalyticsEvent({
		...payload,
		eventType: UI_EVENT_TYPE,
		attributes: {
			...payload.attributes,
		},
		...getTenantFields(analyticsContext),
	});
	analyticsEvent.clone();
	analyticsEvent.fire(NOTIFICATION_CHANNEL);
};

export const triggerOperationalEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	payload: NotificationGasOperationalEventPayload,
	analyticsContext: AnalyticsContext,
) => {
	createAnalyticsEvent({
		...payload,
		eventType: OPERATIONAL_EVENT_TYPE,
		attributes: {
			...payload.attributes,
		},
		...getTenantFields(analyticsContext),
	}).fire(NOTIFICATION_CHANNEL);
};

export const triggerTrackEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	payload: NotificationGasEventPayload,
	analyticsContext: AnalyticsContext,
) => {
	const analyticsEvent = createAnalyticsEvent({
		...payload,
		eventType: TRACK_EVENT_TYPE,
		attributes: {
			...payload.attributes,
		},
		...getTenantFields(analyticsContext),
	});
	analyticsEvent.clone();
	analyticsEvent.fire(NOTIFICATION_CHANNEL);
};

const getUserHourOfDay = () => new Date().getHours();

const getTenantFields = (analyticsContext: AnalyticsContext) => {
	if (analyticsContext.cloudId) {
		return { tenantIdType: 'cloudId', tenantId: analyticsContext.cloudId };
	} else {
		return { tenantIdType: 'none' };
	}
};

const isCrossSite = (notification: Notification, analyticsContext: AnalyticsContext) => {
	if (
		analyticsContext.cloudId === undefined ||
		notification.analyticsAttributes.cloudId === undefined
	) {
		return CrossType.UNKNOWN;
	} else if (
		notification.analyticsAttributes.cloudId &&
		analyticsContext.cloudId.toUpperCase() !==
			notification.analyticsAttributes.cloudId.toUpperCase()
	) {
		return CrossType.TRUE;
	}
	return CrossType.FALSE;
};

const isCrossProduct = (notification: Notification, analyticsContext: AnalyticsContext) => {
	if (
		analyticsContext.product === undefined ||
		notification.analyticsAttributes.registrationProduct === undefined
	) {
		return CrossType.UNKNOWN;
	} else if (
		analyticsContext.product.toUpperCase() ===
		notification.analyticsAttributes.registrationProduct.toUpperCase()
	) {
		return CrossType.FALSE;
	}
	return CrossType.TRUE;
};

const INFINITY = '+Inf';

export const getRenderBucket = (renderTime: number, buckets: string[]): string | null => {
	return renderTime >= 0
		? buckets.reduce((lowestBucket, currentBucket) => {
				return renderTime <= parseInt(currentBucket) ? currentBucket : lowestBucket;
			}, INFINITY)
		: null;
};

export const getDefaultAttributes = (analyticsContext: AnalyticsContext) => ({
	userHourOfDay: getUserHourOfDay(),
	readStateFilter: analyticsContext.readStateFilter,
	categoryFilter: analyticsContext.requestCategory,
	productFilter: analyticsContext.productFilter,
	product: analyticsContext.product,
	view: analyticsContext.view,
});

export const getNotificationAttributes = (notification: Notification, listIndex?: number) => {
	return {
		listIndex,
		notificationAgeInSeconds: Math.floor(
			(Date.now() - new Date(notification.timestamp).getTime()) / 1000,
		),
		notificationCategory: notification.category || 'watching',
		notificationReadState: notification.readState,
		...notification.analyticsAttributes,
	};
};

export const getNotificationGroupAttributes = (
	notificationGroup: NotificationGroup,
	listIndex: number,
) => {
	const byMostRecent = <T extends { timestamp: string }>(a: T, b: T) =>
		new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

	const mostRecentNotification = notificationGroup.notifications
		.sort(byMostRecent)
		.find(() => true);

	if (mostRecentNotification === undefined) {
		return {};
	}

	return getNotificationAttributes(mostRecentNotification, listIndex);
};

export const getCrossAttributes = (
	notification: Notification,
	analyticsContext: AnalyticsContext,
) => {
	return {
		isCrossSite: isCrossSite(notification, analyticsContext),
		isCrossProduct: isCrossProduct(notification, analyticsContext),

		// PlatformExperienceCommonContext
		platformCtx: 'notifications',
		destinationProduct: notification.analyticsAttributes.registrationProduct || '_unknown',
		destinationObjectId: notification.id,
		destinationTenantId: notification.analyticsAttributes.cloudId || '_unknown',
	};
};

export const getNotificationContainer = (notification: Notification) => {
	return {
		containers: {
			notification: {
				id: notification.id,
			},
		},
	};
};

import { useContext, useMemo } from 'react';

import { type CreateUIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	type ActionAppearance,
	type AnalyticsContext,
	MarkRequestReadState,
	type Notification,
	type NotificationGroup,
	type ProductFilters,
	RequestCategory,
} from '@atlassian/notification-types/types';

import { NotificationsStoreContext } from '../../ui/notifications-context';
import { type AbortReason } from '../experience-tracking/types';

import { type LinkType, type MarkAllReadLocation, type SloFailureReason } from './types';
import {
	getCrossAttributes,
	getDefaultAttributes,
	getNotificationAttributes,
	getNotificationContainer,
	getNotificationGroupAttributes,
	getRenderBucket,
	triggerOperationalEvent,
	triggerScreenEvent,
	triggerTrackEvent,
	triggerUIEvent,
} from './utils';

/**
 * SCREEN events
 */

export const triggerNotificationFilterViewed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(firstRender: boolean, firstFilterView: boolean) => {
		triggerScreenEvent(
			createAnalyticsEvent,
			{
				name: analyticsContext.screenName,
				attributes: {
					firstFilterView: firstFilterView.toString(),
					firstRender: firstRender.toString(),
					...getDefaultAttributes(analyticsContext),
				},
			},
			analyticsContext,
		);
	};

export const triggerShortcutsDialogViewed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) => () => {
		triggerScreenEvent(
			createAnalyticsEvent,
			{
				name: 'notificationsKeyboardShortcutsDialog',
				attributes: {
					...getDefaultAttributes(analyticsContext),
				},
			},
			analyticsContext,
		);
	};

export const triggerRequestNotificationViewed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) => () => {
		triggerScreenEvent(
			createAnalyticsEvent,
			{
				name: 'requestNotification',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					categoryFilter: RequestCategory.DIRECT,
				},
			},
			analyticsContext,
		);
	};

/**
 * UI events
 */

export const triggerHeaderDropdownMenuClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(menuState: 'expanded' | 'collapsed') => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dropdownMenu',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					menuState,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationLinkClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(notification: Notification, listIndex: number, linkType: LinkType, clickType?: string) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'notificationItem',
				attributes: {
					...getCrossAttributes(notification, analyticsContext),
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					clickType,
					linkType,
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);

		/**
		 * @Deprecated please remove from 01/07/2023
		 * https://product-fabric.atlassian.net/browse/NP-5681
		 */
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: linkType,
				attributes: {
					...getCrossAttributes(notification, analyticsContext),
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					clickType,
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerConfluenceCommentReplyButtonClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(notification: Notification, listIndex: number) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'replyToComment',
				attributes: {
					...getCrossAttributes(notification, analyticsContext),
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerReactionCreatedInContext =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		notification: Notification,
		listIndex: number,
		contentId: string,
		contentType: string,
		containerId: string,
		containerType: string,
		emojiId: string,
		userId: string,
	) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'created',
				actionSubject: 'reaction',
				attributes: {
					...getCrossAttributes(notification, analyticsContext),
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					objectId: contentId,
					objectType: contentType, // inline comment, comment
					containerId,
					containerType,
					emojiId,
					accountId: userId,
					displayType: 'notification-bell-drawer-comment',
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerReactionDeletedInContext =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		notification: Notification,
		listIndex: number,
		contentId: string,
		contentType: string,
		containerId: string,
		containerType: string,
		emojiId: string,
		userId: string,
	) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'deleted',
				actionSubject: 'reaction',
				attributes: {
					...getCrossAttributes(notification, analyticsContext),
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					objectId: contentId,
					objectType: contentType, // inline comment, footer comment
					containerId,
					containerType,
					emojiId,
					accountId: userId,
					displayType: 'notification-bell-drawer-comment',
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerDocumentExpanded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(notification: Notification, listIndex: number) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'expanded',
				actionSubject: 'notification',
				actionSubjectId: 'document',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					feature: 'expand',
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerDocumentCollapsed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(notification: Notification, listIndex: number) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'collapsed',
				actionSubject: 'notification',
				actionSubjectId: 'document',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					feature: 'expand',
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerNotificationGroupExpanded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(action: 'expanded' | 'collapsed', group: NotificationGroup, listIndex: number) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action,
				actionSubject: 'notification',
				actionSubjectId: 'group',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationGroupAttributes(group, listIndex),
					objectType: 'notificationGroup',
					objectId: group.id,
					groupSize: group.size,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsViewToggled =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(toView: 'listView' | 'groupedView') => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'toggleNotificationsView',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					toView,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsGiveFeedback =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) => () => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'giveFeedback',
				attributes: {
					...getDefaultAttributes(analyticsContext),
				},
			},
			analyticsContext,
		);
	};

export const triggerGroupingOnboardingAction =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		actionSubjectId:
			| 'groupingOnboardingDismissed'
			| 'groupingOnboardingNextStep'
			| 'groupingOnboardingGoBack',
		step?: number,
	) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId,
				attributes: {
					...getDefaultAttributes(analyticsContext),
					step,
				},
			},
			analyticsContext,
		);
	};

export const triggerViewedAllNotificationsUIEvent =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(viewedCount: number) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'viewed',
				actionSubject: 'message',
				actionSubjectId: 'allNotificationsViewed',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					viewedCount,
				},
			},
			analyticsContext,
		);
	};

export const triggerFPNLinkClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) => () => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'fullPageNotifications',
				attributes: {
					...getDefaultAttributes(analyticsContext),
				},
			},
			analyticsContext,
		);
	};

export const triggerRequestTabClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(categoryFilter: RequestCategory, inTabsStickyHeader: boolean) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'tab',
				actionSubjectId: 'notificationTab',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					categoryFilter,
					inTabsStickyHeader,
				},
			},
			analyticsContext,
		);
	};

export const triggerActionCtaButtonClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(actionAppearance: ActionAppearance, notification: Notification, index: number) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: actionAppearance.toLocaleLowerCase() + 'Action',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification),
					actionButtonIndex: index,
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerNotificationProductFilterClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(selectedProduct: ProductFilters) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'fullPageNotificationsProductFilter',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					productFilter: selectedProduct,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationCategoryFilterClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(selectedCategory: RequestCategory) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'fullPageNotificationsCategoryFilter',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					categoryFilter: selectedCategory,
				},
			},
			analyticsContext,
		);
	};

/**
 * Track events
 */

export const triggerNotificationViewedTrackEvent =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(notification: Notification, listIndex: number, notificationViewedDuration: number) => {
		triggerTrackEvent(
			createAnalyticsEvent,
			{
				action: 'viewed',
				actionSubject: 'notification',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					viewedDuration: notificationViewedDuration,
					viewed_duration: notificationViewedDuration,
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerFullPageNotificationViewedTrackEvent =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(fullPageNotificationViewedDuration: number) => {
		triggerTrackEvent(
			createAnalyticsEvent,
			{
				action: 'viewed',
				actionSubject: 'page',
				actionSubjectId: 'fullPageNotifications',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					viewedDuration: fullPageNotificationViewedDuration,
				},
			},
			analyticsContext,
		);
	};

export const triggerMarkAllReadButtonClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(triggeredFrom: MarkAllReadLocation) => {
		triggerTrackEvent(
			createAnalyticsEvent,
			{
				action: 'marked',
				actionSubject: 'notification',
				actionSubjectId: 'all',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					toState: MarkRequestReadState.READ.toLocaleLowerCase(),
					markType: 'all',
					triggeredFrom,
				},
			},
			analyticsContext,
		);
	};

export const triggerMarkOneReadButtonClicked =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(toReadState: MarkRequestReadState, notification: Notification, listIndex: number) => {
		triggerTrackEvent(
			createAnalyticsEvent,
			{
				action: 'marked',
				actionSubject: 'notification',
				actionSubjectId: 'single',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
					toState: toReadState.toLocaleLowerCase(),
					markType: 'single',
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerMarkNotificationGroup =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(toReadState: MarkRequestReadState, group: NotificationGroup, listIndex: number) => {
		triggerTrackEvent(
			createAnalyticsEvent,
			{
				action: 'marked',
				actionSubject: 'notification',
				actionSubjectId: 'group',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationGroupAttributes(group, listIndex),
					toState: toReadState.toLocaleLowerCase(),
					markType: 'group',
					groupSize: group.size,
					objectId: group.id,
					objectType: 'notificationGroup',
				},
			},
			analyticsContext,
		);
	};

export const triggerKeyboardShortcutUsed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(shortcut: string, notification: Notification, listIndex: number) => {
		triggerUIEvent(
			createAnalyticsEvent,
			{
				action: 'pressed',
				actionSubject: 'keyboardShortcut',
				actionSubjectId: `${shortcut}KeyboardShortcut`,
				attributes: {
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

export const triggerConfluenceCommentReplyCreated =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		notification: Notification,
		listIndex: number,
		commentId: string,
		containerId: string,
		containerType: string,
	) => {
		triggerTrackEvent(
			createAnalyticsEvent,
			{
				action: 'created',
				actionSubject: 'comment',
				actionSubjectId: commentId,
				attributes: {
					objectType: containerType,
					objectId: containerId,
					...getCrossAttributes(notification, analyticsContext),
					...getDefaultAttributes(analyticsContext),
					...getNotificationAttributes(notification, listIndex),
				},
				...getNotificationContainer(notification),
			},
			analyticsContext,
		);
	};

/**
 * Trigger operational events
 */

export const triggerErrorBoundaryRenderedEvent =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		errorBoundaryType: string,
		isCritical = false,
		notificationContext?: Notification,
		listIndex?: number,
	) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'rendered',
				actionSubject: 'errorBoundary',
				actionSubjectId: 'notifications',
				attributes: {
					errorBoundaryType,
					isCritical,
					...getDefaultAttributes(analyticsContext),
					...(notificationContext &&
						listIndex !== undefined &&
						getNotificationAttributes(notificationContext, listIndex)),
				},
				...(notificationContext &&
					listIndex !== undefined &&
					getNotificationContainer(notificationContext)),
			},
			analyticsContext,
		);
	};

export const triggerFailedToRetrieveNotificationsEvent =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(notificationsRendered: number) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'rendered',
				actionSubject: 'errorBoundary',
				actionSubjectId: 'notificationRetrieval',
				attributes: {
					errorBoundaryType: 'notificationRetrieval',
					notificationsRendered,
					...getDefaultAttributes(analyticsContext),
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsAPICallSucceeded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(route: string, additionalAttributes = {}) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'succeeded',
				actionSubject: 'notificationsAPI',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					route,
					...additionalAttributes,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsAPICallFailed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(route: string, statusCode: number, additionalAttributes = {}) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'failed',
				actionSubject: 'notificationsAPI',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					route,
					statusCode,
					...additionalAttributes,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsLoaded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		firstLoad: boolean,
		withContent: boolean,
		duration: number,
		limit: number,
		route: '/notifications' | '/notification-groups' = '/notifications',
	) => {
		const actionSubject = withContent ? 'notificationsWithContent' : 'notificationsWithoutContent';

		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'fetched',
				actionSubject,
				attributes: {
					...getDefaultAttributes(analyticsContext),
					firstLoad,
					duration,
					bucket: getRenderBucket(
						duration,
						withContent ? withContentBuckets : withoutContentBuckets,
					),
					limit,
					route,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsFailedToLoad =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		firstLoad: boolean,
		withContent: boolean,
		limit: number,
		statusCode: number,
		route: '/notifications' | '/notification-groups' = '/notifications',
	) => {
		const actionSubject = withContent ? 'notificationsWithContent' : 'notificationsWithoutContent';

		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'failedFetch',
				actionSubject,
				attributes: {
					...getDefaultAttributes(analyticsContext),
					firstLoad,
					limit,
					statusCode,
					route,
				},
			},
			analyticsContext,
		);
	};

export const triggerFetchConfluenceCommentDataInContextSucceeded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, commentId: string, duration: number) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'succeeded',
				actionSubject: 'commentData',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					commentId,
					duration,
				},
			},
			analyticsContext,
		);
	};

export const triggerFetchConfluenceCommentDataInContextFailed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, commentId: string, duration: number, reason: SloFailureReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'failed',
				actionSubject: 'commentData',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					commentId,
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerFetchConfluenceCommentDataInContextAborted =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, duration: number, reason: AbortReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'aborted',
				actionSubject: 'commentData',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerCommentReplyInContextSucceeded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, mutationType: string, containerType: string, duration: number) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'succeeded',
				actionSubject: 'commentReply',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					containerType,
					mutationType,
					duration,
				},
			},
			analyticsContext,
		);
	};

export const triggerCommentReplyInContextFailed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		name: string,
		mutationType: string,
		containerType: string,
		duration: number,
		reason: SloFailureReason,
	) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'failed',
				actionSubject: 'commentReply',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					containerType,
					mutationType,
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerCommentReplyInContextAborted =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, duration: number, reason: AbortReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'aborted',
				actionSubject: 'commentReply',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerI18nFailedToLoad =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(locale: string) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'fetchFailed',
				actionSubject: 'i18nMessages',
				attributes: {
					locale,
				},
			},
			analyticsContext,
		);
	};

const withoutContentBuckets = ['8000', '6000', '4000', '3000', '2500', '2000', '1000', '500'];
const withContentBuckets = ['15000', '10000', '8000', '6000', '4000', '2000', '1000'];
export const triggerNotificationsRenderSucceeded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(firstLoad: boolean, withContent: boolean, duration: number, noNotificationsScreen: boolean) => {
		const actionSubject = withContent ? 'notificationsWithContent' : 'notificationsWithoutContent';
		const buckets = withContent ? withContentBuckets : withoutContentBuckets;

		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'renderSucceeded',
				actionSubject,
				attributes: {
					...getDefaultAttributes(analyticsContext),
					firstLoad,
					duration,
					bucket: getRenderBucket(duration, buckets),
					noNotificationsScreen,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsRenderFailed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		firstLoad: boolean,
		withContent: boolean,
		duration: number,
		failureReason: SloFailureReason,
		extraAttributes: { [key: string]: any },
	) => {
		const actionSubject = withContent ? 'notificationsWithContent' : 'notificationsWithoutContent';
		const buckets = withContent ? withContentBuckets : withoutContentBuckets;

		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'renderFailed',
				actionSubject,
				attributes: {
					...getDefaultAttributes(analyticsContext),
					firstLoad,
					duration,
					bucket: getRenderBucket(duration, buckets),
					failureReason,
					...extraAttributes,
				},
			},
			analyticsContext,
		);
	};

export const triggerNotificationsRenderAborted =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(
		firstLoad: boolean,
		withContent: boolean,
		duration: number,
		abortReason: AbortReason,
		extraAttributes: { [key: string]: any },
	) => {
		const actionSubject = withContent ? 'notificationsWithContent' : 'notificationsWithoutContent';
		const buckets = withContent ? withContentBuckets : withoutContentBuckets;

		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'renderAborted',
				actionSubject,
				attributes: {
					...getDefaultAttributes(analyticsContext),
					firstLoad,
					duration,
					bucket: getRenderBucket(duration, buckets),
					abortReason,
					...extraAttributes,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionAddedInContextSucceeded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, emojiId: string, duration: number) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'succeeded',
				actionSubject: 'reactionAdded',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					emojiId,
					displayType: 'notification-bell-drawer-comment',
					duration,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionAddedInContextFailed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, emojiId: string, duration: number, reason: SloFailureReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'failed',
				actionSubject: 'reactionAdded',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					emojiId,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionAddedInContextAborted =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, emojiId: string, duration: number, reason: AbortReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'aborted',
				actionSubject: 'reactionAdded',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					emojiId,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionDeletedInContextSucceeded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, emojiId: string, duration: number) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'succeeded',
				actionSubject: 'reactionDeleted',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					emojiId,
					displayType: 'notification-bell-drawer-comment',
					duration,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionDeletedInContextFailed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, emojiId: string, duration: number, reason: SloFailureReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'failed',
				actionSubject: 'reactionDeleted',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					emojiId,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionDeletedInContextAborted =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, emojiId: string, duration: number, reason: AbortReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'aborted',
				actionSubject: 'reactionDeleted',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					emojiId,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionSummaryInContextSucceeded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, duration: number) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'succeeded',
				actionSubject: 'reactionSummary',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					duration,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionSummaryInContextFailed =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, duration: number, reason: SloFailureReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'failed',
				actionSubject: 'reactionSummary',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

export const triggerReactionSummaryInContextAborted =
	(createAnalyticsEvent: CreateUIAnalyticsEvent, analyticsContext: AnalyticsContext) =>
	(name: string, duration: number, reason: AbortReason) => {
		triggerOperationalEvent(
			createAnalyticsEvent,
			{
				action: 'aborted',
				actionSubject: 'reactionSummary',
				attributes: {
					...getDefaultAttributes(analyticsContext),
					name,
					displayType: 'notification-bell-drawer-comment',
					duration,
					reason,
				},
			},
			analyticsContext,
		);
	};

/**
 * Misc.
 */

export const useCreateFireAnalyticsFromTrigger = <T,>(
	createTrigger: (
		createUIAnalyticEvent: CreateUIAnalyticsEvent,
		analyticsContext: AnalyticsContext,
	) => T,
) => {
	const { analyticsContext } = useContext(NotificationsStoreContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const currentAnalyticsContext = analyticsContext;

	return useMemo(() => {
		return createTrigger(createAnalyticsEvent, currentAnalyticsContext);
	}, [createAnalyticsEvent, createTrigger, currentAnalyticsContext]);
};

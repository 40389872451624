export enum LinkType {
	MAIN_ACTION = 'mainAction',
	ENTITY = 'entity',
	PATH = 'path',
	FPN_LINK = 'fullPageNotifications',
	OPEN_IN_NEW_TAB_BUTTON = 'openInNewTabButton',
	OPEN_FULL_COMMENT_THREAD = 'openFullCommentThread',
}

export type ClickType = 'left' | 'middle' | 'right';

export enum SloFailureReason {
	FETCH_FAILED = 'fetch-failed',
	ADF_RENDER_FAILED = 'adf-render-failed',
	ROOT_ERROR_BOUNDARY = 'root-error-boundary',
	ABORT_TIMEOUT = 'abort-timeout',
	UNKNOWN = 'unknown',
}

/* A type for whether a click is cross-product/cross-site.
We don't know if something is cross-site/cross-product if the
original site and product are not provided, so this is encoded
in the unknown data type. */
export enum CrossType {
	TRUE = 'true',
	FALSE = 'false',
	UNKNOWN = 'unknown',
}

export type NotificationsAnalyticsContextData = {
	packageName: string;
	packageVersion: string;
	source: string;
	component: string;
};

export type MarkAllReadLocation = 'dropdownMenu' | 'notificationFullPage';

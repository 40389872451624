import { createContext, useContext } from 'react';

import {
	type AnalyticsContext,
	ProductFilters,
	RequestCategory,
	RequestReadState,
} from '@atlassian/notification-types/types';

export type NotificationsStoreContext = {
	// Prefer this to React.RefObject because "current" should never be null in our implementation
	// Official Typescript always allows "null"
	analyticsContext: AnalyticsContext;
	intlLocale: { readonly current: string };
	packageName: string;
	packageVersion: string;
	updateIntlLocale: (intlLocale: string) => void;
	userId?: string;
};

export const NotificationsStoreContext = createContext<NotificationsStoreContext>({
	analyticsContext: {
		product: 'unset',
		requestCategory: RequestCategory.DIRECT,
		readStateFilter: RequestReadState.ANY,
		productFilter: ProductFilters.ANY,
		screenName: '',
		view: 'list',
	},
	intlLocale: {
		current: 'en-US',
	},
	packageName: '@atlassian/notification-common',
	packageVersion: 'v9.9.9',
	updateIntlLocale: () => {},
	userId: undefined,
});

export const useOriginId = () => {
	const notificationsContext = useContext(NotificationsStoreContext);

	return notificationsContext.analyticsContext.originId;
};
